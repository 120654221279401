/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 16:42:26
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:59:13
 */
import { copyText } from '@/plugins/utils'
export default {
  name: 'ActivitySuccess',
  data () {
    return {
      WActivitySuccessShow: false,
      form: {
        url: '',
        id: ''
      }
    }
  },
  methods: {
    show () {
      this.WActivitySuccessShow = true
    },
    hide () {
      this.WActivitySuccessShow = false
    },
    copyUrl () {
      return copyText(this.form.url)
    },
    cancel () {
      this.$router.replace({ path: '/app/activity' })
    },
    test () {
      this.$router.replace({ path: `/app/activity/test/${this.form.id}`, query: { url: this.form.url } })
    },
    updateForm (payload) {
      for (const i in payload) {
        this.form[i] = payload[i]
      }
    }
  }
}
