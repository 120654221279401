/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 16:42:38
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 16:43:51
 */
import ActivitySuccess from './ActivitySuccess.vue'
export default ActivitySuccess
